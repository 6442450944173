var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--why-use-airalo_item why-use-airalo-card-item"
  }, [_c('nuxt-img', {
    staticClass: "img",
    attrs: {
      "src": _vm.img,
      "alt": _vm.title,
      "width": "175",
      "height": "131",
      "loading": "lazy"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.content))]), _vm._v(" "), _vm.review ? _c('div', {
    staticClass: "why-use-airalo-card-item-review"
  }, [_c('div', {
    staticClass: "why-use-airalo-card-item-review-ratingWrapper flex-md-row align-items-md-center"
  }, [_c('div', {
    staticClass: "why-use-airalo-card-item-review-rating"
  }, [_vm.getRatingStoreIcon(_vm.review[0].source) ? _c('a-icon', {
    attrs: {
      "loader": "loader",
      "name": "icon-hero-rating",
      "theme": _vm.getRatingStoreIcon(_vm.review[0].source),
      "width": "28",
      "height": "28"
    }
  }) : _vm._e(), _vm._v(" "), _c('raiting', {
    staticClass: "why-use-airalo-card-item-review-rating-value",
    attrs: {
      "value": _vm.review[0].rating,
      "width": 22,
      "color": ['#FBB034', '#FFCC00'],
      "unrated-color": ['#DDDDDD', '#DDDDDD']
    }
  })], 1), _vm._v(" "), _c('header-right', {
    attrs: {
      "reviewer": _vm.review[0].customer_name
    }
  })], 1), _vm._v(" "), _c('p', {
    staticClass: "review-title"
  }, [_vm._v(_vm._s(_vm.review[0].title))]), _vm._v(" "), _c('span', {
    staticClass: "review-description"
  }, [_vm._v("\n      " + _vm._s(_vm.review[0].content) + "\n    ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }