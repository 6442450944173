<template>
  <div class="c--why-use-airalo_item why-use-airalo-card-item">
    <nuxt-img :src="img" :alt="title" width="175" height="131" loading="lazy" class="img" />
    <h2 class="title">{{ title }}</h2>
    <p class="content">{{ content }}</p>

    <div class="why-use-airalo-card-item-review" v-if="review">
      <div class="why-use-airalo-card-item-review-ratingWrapper flex-md-row align-items-md-center">
        <div class="why-use-airalo-card-item-review-rating">
          <a-icon
            v-if="getRatingStoreIcon(review[0].source)"
            loader="loader"
            name="icon-hero-rating"
            :theme="getRatingStoreIcon(review[0].source)"
            width="28"
            height="28"
          />
          <raiting
            :value="review[0].rating"
            :width="22"
            :color="['#FBB034', '#FFCC00']"
            :unrated-color="['#DDDDDD', '#DDDDDD']"
            class="why-use-airalo-card-item-review-rating-value"
          />
        </div>
        <header-right :reviewer="review[0].customer_name" />
      </div>

      <p class="review-title">{{ review[0].title }}</p>
      <span class="review-description">
        {{ review[0].content }}
      </span>
    </div>
  </div>
</template>

<script>
  import Raiting from '~/components/shared/raiting.vue'
  import HeaderRight from '~/components/customer-reviews/header-right.vue'

  export default {
    name: 'item',
    components: {
      Raiting,
      HeaderRight,
    },

    props: ['img', 'title', 'content', 'review'],

    methods: {
      getRatingStoreIcon(source) {
        if (source === 'AppStore') return 'appstore'
        if (source === 'Google Play Store') return 'googleplay'

        return null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .why-use-airalo-card-item {
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    text-align: center;

    .img {
      margin-top: 20px;
      width: 100%;
      max-width: 175px;
      height: auto;
    }
    .title {
      color: #4a4a4a;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 25px;
      margin: 20px 0 10px 0;
      text-align: center;
    }
    .content {
      margin: 0;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 21px;
      color: #afafaf;
      text-align: center;
    }

    &-review {
      margin-top: 40px;
      border-radius: 7px;
      background-color: #fafafa;
      padding: 20px;
      text-align: left;

      [dir='rtl'] & {
        text-align: right;
      }

      .review-title {
        color: #4a4a4a;
        font-size: 21px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .review-description {
        margin-top: 9px;
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;
      }

      &-ratingWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      &-rating {
        display: flex;
        flex-direction: row;
        &-value {
          margin-left: 5px;

          [dir='rtl'] & {
            margin-left: unset;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>
