<template>
  <div class="c--why-use-airalo why-use-airalo home-container-padding">
    <div class="container-fluid">
      <div class="airgsm-container mx-auto">
        <h2 class="why-use-airalo-title title-h2">
          {{ $t('components.why.use.airalo.title') }}
        </h2>
        <div class="why-use-airalo-card-wrapper">
          <CardItem
            v-for="(card, index) in getData"
            :key="`why-use-airalo-card-${index}`"
            :img="card.img"
            :title="card.title"
            :content="card.content"
            :review="card.review"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardItem from '~/components/why-use-airalo/item'
  export default {
    name: 'WhyUseAiralo',
    props: {
      data: {
        required: false,
        default: null,
      },
    },
    components: {
      CardItem,
    },
    data() {
      return {
        cards: [
          {
            img: '/assets/images/svg/Why-Use-Airalo-1.svg',
            title: this.$t('components.why.use.airalo.keep-using-title'),
            content: this.$t('components.why.use.airalo.keep-using.content'),
            section: 'Why Airalo 1',
          },
          {
            img: '/assets/images/svg/Why-Use-Airalo-2.svg',
            title: this.$t('components.why.use.airalo.countries-regions-title'),
            content: this.$t('components.why.use.airalo.countries-regions-content'),
            section: 'Why Airalo 2',
          },
          {
            img: '/assets/images/svg/Why-Use-Airalo-3.svg',
            title: this.$t('components.why.use.airalo.flexible-payment-title'),
            content: this.$t('components.why.use.airalo.flexible-payment-content'),
            section: 'Why Airalo 3',
          },
          {
            img: '/assets/images/svg/Why-Use-Airalo-4.svg',
            title: this.$t('components.why.use.airalo.support-team-title'),
            content: this.$t('components.why.use.airalo.support-team-content'),
            section: 'Why Airalo 4',
          },
        ],
      }
    },

    computed: {
      getData() {
        return this.cards.map((card, i) => {
          let dataWithReview =
            this.data && this.data.length > 0 ? this.data.filter((item) => item.section === card.section) : null

          dataWithReview = dataWithReview && !dataWithReview.length ? null : dataWithReview

          if (dataWithReview) {
            return {
              ...card,
              review: { ...dataWithReview },
            }
          } else {
            return {
              ...card,
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .why-use-airalo {
    &-title {
      color: #4a4a4a;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 32px;
    }

    &-card-wrapper {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media (max-width: 575px) {
    .why-use-airalo {
      .why-use-airalo-card-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
</style>
