var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--customer-reviews_header-right customer-reviews-header-right"
  }, [_c('p', [_vm._v("by " + _vm._s(_vm.reviewer))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }