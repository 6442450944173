var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--why-use-airalo why-use-airalo home-container-padding"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "airgsm-container mx-auto"
  }, [_c('h2', {
    staticClass: "why-use-airalo-title title-h2"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('components.why.use.airalo.title')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "why-use-airalo-card-wrapper"
  }, _vm._l(_vm.getData, function (card, index) {
    return _c('CardItem', {
      key: `why-use-airalo-card-${index}`,
      attrs: {
        "img": card.img,
        "title": card.title,
        "content": card.content,
        "review": card.review
      }
    });
  }), 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }